import React from "react";
import { string } from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import css from "./Testimonials.module.css";
import { intlShape, injectIntl } from "../../util/reactIntl";
import Comma from "./commas.svg";
import CustomCarousel from "../Carousel/Carousel";
import img1 from "./images/img1.jpeg"
import img6 from "./images/img6.jpeg"

const TestimonialsComponent = (props) => {
  const { rootClassName, className, intl, nightMode } = props;
  const mainTitle = intl.formatMessage({ id: "Testimonials.mainTitle" });
  const subTitle = intl.formatMessage({ id: "Testimonials.subTitle" });
  const intro = intl.formatMessage({ id: "Testimonials.intro" });
  const classes = classNames(rootClassName || css.root, className);
  const conatinerClass = classNames(
    { [css.night]: nightMode },
    css.testimonialsContent
  );

  const testimonials = [
    {
      name: "Bruun andreas",
      designation: "Founder and CEO of hofbox",
      review: "I enjoy working with codedvlpr. Quick and very professional.",
      img: img1,
    },
    {
      name: "Bruun andreas",
      designation: "Founder and CEO of hofbox",
      review: "I enjoy working with codedvlpr. Quick and very professional.",
      img: img6,
    }
  ]

  return (
    <div className={classes} id="Clients">
      <div className={conatinerClass}>
        <h1>{mainTitle}</h1>
        <div className={css.testimonialsTextContent}>
          <img src={Comma} className={css.commaImg} />
          <h2>{subTitle}</h2>
          <p>{intro}</p>
        </div>
      </div>
      <CustomCarousel nightMode={nightMode} testimonials={testimonials} />
    </div>
  );
};

TestimonialsComponent.defaultProps = { rootClassName: null, className: null };

TestimonialsComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const Testimonials = compose(injectIntl)(TestimonialsComponent);

Testimonials.displayName = "Testimonials";

export default React.memo(Testimonials);
